export enum ExternalHref {
  thresholdGithub = "https://github.com/threshold-network/token-dashboard",
  thresholdDiscord = "https://discord.threshold.network/",
  metamaskHomePage = "https://metamask.io/",
  stakingContractLeanMore = "https://github.com/threshold-network/solidity-contracts/issues/53",
  tacoNodeSetup = "https://docs.threshold.network/staking-and-running-a-node/running-a-node/self-managed/taco-node-setup",
  tacoStakingProvidersList = "https://docs.threshold.network/staking-and-running-a-node/running-a-node/staking-providers",
  exchangeRateLearnMore = "https://blog.threshold.network/threshold-launch/",
  keepDapp = "https://dashboard.keep.network/",
  keepDappAuthPage = "https://dashboard.keep.network/applications/threshold",
  nuDapp = "https://stake.nucypher.network/manage",
  stakingBonusDocs = "https://forum.threshold.network/t/interim-era-incentive-schemes-1-one-off-migration-stake-bonus-2-ongoing-stable-yield/297",
  stakedUs = "https://staked.us/",
  bisonTrails = "https://bisontrails.co/",
  boar = "https://boar.network/",
  figment = "https://www.figment.io/",
  lowFeeValidation = "https://lowfeevalidation.com/",
  ankr = "https://www.ankr.com/",
  p2pValidator = "https://p2p.org/",
  infStones = "https://infstones.com/",
  setupNodes = "https://docs.threshold.network/guides/threshold-applications/tbtc-v2-client-setup",
  tbtcNodeDocs = "https://docs.threshold.network/guides/threshold-applications/tbtc-v2-client-setup",
  tacoNodeDocs = "https://docs.threshold.network/staking-and-running-a-node/running-a-node/self-managed/taco-node-setup",
  randomBeaconNodeDocs = "https://docs.threshold.network/guides/threshold-applications/tbtc-v2-client-setup",
  analyticsReports = "SOME_URL",
  btcRecoveryAddress = "https://github.com/keep-network/tbtc-v2/blob/main/docs/rfc/rfc-1.adoc",
  tbtcBridgeGithub = "https://github.com/keep-network/tbtc-v2/blob/853bc8ff0601c82bb02cfaa0c904ba98d7d92fe5/solidity/contracts/bridge/Bridge.sol",
  tBTCBrdigeAudit = "https://leastauthority.com/blog/audits/audit-of-keep-network-tbtc-bridge-v2/",
  vendingMachineAudit = "https://www.certik.com/projects/threshold-network",
  thresholdStakingAudit = "https://chainsecurity.com/security-audit/threshold-network/",
  installTaho = "https://taho.xyz/",
  setTahoAsDefaultWallet = "https://tahowallet.notion.site/Taho-is-interfering-with-Metamask-or-another-wallet-6005819d4e8748f5b7d1ed788da8826c",
  tBTCRecoveryGuide = "https://github.com/keep-network/tbtc-v2/blob/main/typescript/scripts/README.adoc",
  btcConfirmations = "https://en.bitcoin.it/wiki/Confirmation",
  mintersAndGuardiansDocs = "https://blog.threshold.network/minters-guardians-and-a-strong-tbtc/",
  tBTCDuneDashboard = "https://dune.com/threshold/tbtc",
  delight = "https://delightlabs.io",
  runningNodeDocs = "https://docs.threshold.network/staking-and-running-a-node/running-a-node",
  colossus = "https://colossus.digital",
}
